////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-secondaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-thirdColor {
  background-color: rgb(255, 255, 255);
}
.bg-fourthColor {
  background-color: rgb(238, 166, 60);
}
.bg-fifthColor {
  background-color: rgba(250, 150, 0, 1);
}
.bg-sixthColor {
  background-color: rgb(238, 166, 60);
}
.bg-seventhColor {
  background-color: rgba(238, 166, 60, 1);
}
